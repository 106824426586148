import { NgModule } from "@angular/core";
import { S25RegisterButtonComponent } from "./s25.register.button.component";
import { CommonModule } from "@angular/common";

@NgModule({
    declarations: [S25RegisterButtonComponent],
    imports: [CommonModule],
    providers: [S25RegisterButtonComponent],
    exports: [S25RegisterButtonComponent],
})
export class S25RegisterButtonModule {
    constructor() {}
}
