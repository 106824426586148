import { NgModule } from "@angular/core";
import { S25RadioComponent } from "./s25.radio.component";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";

@NgModule({
    imports: [CommonModule, FormsModule],
    exports: [S25RadioComponent],
    declarations: [S25RadioComponent],
    providers: [S25RadioComponent],
})
export class S25RadioModule {}
