//@author: mandy

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { S25RichTextEditorComponent } from "./s25.rich.text.editor.component";

@NgModule({
    declarations: [S25RichTextEditorComponent],
    imports: [CommonModule, FormsModule],
    providers: [S25RichTextEditorComponent],
    exports: [S25RichTextEditorComponent],
})
export class S25RichTextEditorModule {
    constructor() {}
}
